import {
  mapHelper,
  generateMapByOpts
} from 'common/utils'
import store from '../../../store'

// 跳转类型
const skipType = [{
  text: '至详情页',
  value: 1
},
{
  text: '至功能页',
  value: 2
},
{
  text: '至外部链接',
  value: 3
}
]
// 通知类型
const noticeType = [{
  text: '全部',
  value: 3
},
{
  text: '项目通知',
  value: 1
},
{
  text: '商业通知',
  value: 2
}
]
// 发布人归属类型
const noticeLevel = [{
  text: '集团',
  value: 100
},
{
  text: '区域',
  value: 102
},
{
  text: '项目',
  value: 101
},
{
  text: '组织',
  value: 107
},
{
  text: '平台',
  value: 106
}
]
// 发布范围
const sendType = [{
  text: '个人',
  value: 1
},
{
  text: '项目',
  value: 2
},
{
  text: '全体用户',
  value: 3
},
{
  text: '楼幢',
  value: 4
},
{
  text: '组织',
  value: 5
}, {
  text: '组织超管',
  value: 6
}
]
// 发布类型
const appType = [{
  text: '用户端App',
  value: 1
},
{
  text: '管理端App',
  value: 2
},
{
  text:'商家端App',
  value:3
}
]
// 信息签收
const sign = [{
  text: '是',
  value: 1
},
{
  text: '否',
  value: 0
}
]

// 立即发送
const sendImmediately = [{
  text: '是',
  value: 1
},
{
  text: '否',
  value: 0
}
]
// 签收状态
const signStatus = [{
  text: '已签收',
  value: 2
},
{
  text: '待签收',
  value: 1
}
]
// 组织列表 数据来源
const source = [{
  text: '后台录入',
  value: 1
},
{
  text: 'app申请',
  value: 2
}
]
// 数据来源
const userSource = [{
  text: 'app注册用户',
  value: 1
}]
// 角色类型
const userType = [{
  text: '系统',
  value: '106'
},
{
  text: '集团',
  value: '100'
},
{
  text: '区域',
  value: '102'
},
{
  text: '项目',
  value: '101'
},
{
  text: '商家',
  value: '104'
},
{
  text: '门店',
  value: '105'
},
{
  text: '组织',
  value: '107'
}
]
// 项目状态
const communityStatus = [{
  text: '正常',
  value: 0
},
{
  text: '关闭',
  value: 1
},
{
  text: '作废',
  value: 2
}
]
// 区域类型
const searchRegionType = [
  {
    text: '公司',
    value: 0
  },
  // {
  //   text: '园区公司分公司',
  //   value: 1
  // },
  {
    text: '管理项目组',
    value: 2
  }
]
// 组织列表数据来源
const orgSource = [{
  text: '后台录入',
  value: 1
},
{
  text: 'app申请',
  value: 2
},
{
  text: '组织中心创建',
  value: 3
},
{
  text: '家庭类组织自动创建',
  value: 4
}
]
// 是否顶层组织
const isTop = [{
  text: '是',
  value: 1
},
{
  text: '否',
  value: 0
}
]

// case 0:
//   nodeType = '分公司';
//   break;
// case 1:
//   nodeType = '项目';
//   break;
// case 2:
//   nodeType = '分区';
//   break;
// case 3:
//   nodeType = '组团';
//   break;
// case 4:
//   nodeType = '楼幢';
//   break;
// case 5:
//   nodeType = '单元';
//   break;
// case 6:
//   nodeType = '室';
//   break;
// default:
//   nodeType = '';
//   break;
const nodeLevel = [{
  text: '分公司',
  value: 0
},
{
  text: '项目',
  value: 1
},
{
  text: '分区',
  value: 2
},
{
  text: '组团',
  value: 3
},
{
  text: '楼幢',
  value: 4
},
{
  text: '单元',
  value: 5
},
{
  text: '室',
  value: 6
}
]
// 状态
const statusOps = [
  {
    text: '全部',
    value: undefined
  },
  {
    text: '正常',
    value: 1
  },
  {
    text: '关闭',
    value: 0
  }
]

//标签层级
const labelLevelOps=[
  {
    text: '全部',
    value: undefined
  },
  {
    text: '平台',
    value: 106
  },
  {
    text: '公司',
    value: 102
  },
  {
    text: '项目',
    value: 101
  }
]

/* 商家相关 */
// 整合类型
const _integrationType = {
  0: '项目',
  1: '公司',
  2: '平台'
}

// 整合类型
const integrationTypeOps = [
  {
    text: '全部',
    value: undefined
  },
  {
    text: '平台',
    value: 2
  },
  {
    text: '公司',
    value: 1
  },
  {
    text: '项目',
    value: 0
  }
]
// 经营主体
const isSelfsupportOps = [
  {
    text: '全部',
    value: undefined
  },
  {
    text: '自营',
    value: 0
  },
  {
    text: '他营',
    value: 1
  }
]
// 是否支持售后
const isSupportAftersalesOps = [
  {
    text: '全部',
    value: undefined
  },
  {
    text: '是',
    value: 0
  },
  {
    text: '否',
    value: 1
  }
]
// 商家状态
const isOpenOps = [
  {
    text: '全部',
    value: undefined
  },
  {
    text: '正常',
    value: 0
  },
  {
    text: '关闭',
    value: 1
  }
]

/* 门店相关 */
// 服务范围
const serviceScopeOps = [
  {
    text: '全部',
    value: undefined
  },
  {
    text: '行政范围',
    value: 2
  },
  {
    text: '项目内用户',
    value: 3
  }
]
// 配送方式
const deliveryTypeOps = [
  {
    text: '全部',
    value: undefined
  },
  {
    text: '无物流',
    value: 0
  },
  {
    text: '快递配送',
    value: 1
  },
  {
    text: '预约上门',
    value: 2
  },
  {
    text: '用户自提',
    value: 3
  }
]
// 门店状态
const storeStatusOps = [
  {
    text: '全部',
    value: undefined
  },
  {
    text: '正常',
    value: 0
  },
  {
    text: '关闭',
    value: 1
  }
]
// 是否支持外赠
const isGrant = [
  {
    text: '支持',
    value: 1
  },
  {
    text: '不支持',
    value: 0
  }
]

const {
  map: skipTypeMap,
  setOps: skipTypeOps
} = mapHelper.setMap(skipType)
const {
  map: noticeTypeMap,
  setOps: noticeTypeOps
} = mapHelper.setMap(noticeType)
const {
  map: noticeLevelMap,
  setOps: noticeLevelOps
} = mapHelper.setMap(noticeLevel)
const {
  map: sendTypeMap,
  setOps: sendTypeOps
} = mapHelper.setMap(sendType)
const {
  map: appTypeMap,
  setOps: appTypeOps
} = mapHelper.setMap(appType)
const {
  map: signMap,
  setOps: signOps
} = mapHelper.setMap(sign)
const {
  map: signStatusMap,
  setOps: signStatusOps
} = mapHelper.setMap(signStatus)
const {
  map: userSourceMap,
  setOps: userSourceOps
} = mapHelper.setMap(userSource)
const {
  map: userTypeMap,
  setOps: userTypeOps
} = mapHelper.setMap(userType)
const {
  map: communityStatusMap,
  setOps: communityStatusOps
} = mapHelper.setMap(communityStatus)
const {
  map: searchRegionTypeMap,
  setOps: searchRegionTypeOps
} = mapHelper.setMap(searchRegionType)
const {
  map: orgSourceMap,
  setOps: orgSourceOps
} = mapHelper.setMap(orgSource)
const {
  map: isTopMap,
  setOps: isTopOps
} = mapHelper.setMap(isTop)
const {
  map: sourceMap,
  setOps: sourceOps
} = mapHelper.setMap(source)
const {
  map: nodeLevelMap,
  setOps: nodeLevelOps
} = mapHelper.setMap(nodeLevel)
const {
  map: sendImmediatelyMap,
  setOps: sendImmediatelyOps
} = mapHelper.setMap(sendImmediately)
const statusMap = generateMapByOpts(statusOps)

// 门店相关
const serviceScopeMap = generateMapByOpts(serviceScopeOps)
const deliveryTypeMap = generateMapByOpts(deliveryTypeOps)
const storeStatusMap = generateMapByOpts(storeStatusOps)
const {
  map: isGrantMap,
  setOps: setIsGrantOps
} = mapHelper.setMap(isGrant)

// jump-go校验参数映射
const jumpGoCodeMap = {
  storeCode: 'storeIdList', // 门店
  productCode: 'productIdList', // 商品
  secKillCode: 'secKillIdList', // 秒杀
  discountTopicCode: 'discountTopicId', // 促销专题
  knowledgeInfoCode: 'knowledgeInfoIdList', // 资讯
  postsCode: 'tableIdList', // 帖子
  groupCode: 'groupIdList', // 社群
  socialActivityCode: 'socialActivityIdList', // 社区活动
  surveyCode: 'questionIdList', // 问卷调查
  selfRuleCode: 'selfRuleIdList', // 自治规约
  autonomyVoteCode: 'questionNaireIdList', // 自治表决
  workRoomCode: 'workRoomIdList' // 社区工作室
}

// const sendTypeMapPermission = new Map([
//   ['个人', 'sendPersonal'],
//   ['项目', 'sendCommunity'],
//   ['全体用户', 'sendAll'],
//   ['楼幢', 'sendBuilding'],
//   [`${store.state.baseConfig.docConfig.ORG_NAME || ''}`, 'sendOrg'],
//   [`${store.state.baseConfig.docConfig.ORG_NAME || ''}超管`, 'sendOrgSuperManager'],
//   [`${''}`, 'sendOrg'],
//   [`${''}超管`, 'sendOrgSuperManager'],
//   ['项目内企业管理员', 'sendBuildOrgManager'],
//   ['手机号', 'sendPersonal']
// ])

export {
  skipTypeMap,
  skipTypeOps,
  noticeTypeMap,
  noticeTypeOps,
  noticeLevelMap,
  noticeLevelOps,
  sendTypeMap,
  sendTypeOps,
  appTypeMap,
  appTypeOps,
  signMap,
  signOps,
  signStatusMap,
  signStatusOps,
  userSourceMap,
  userSourceOps,
  userTypeMap,
  userTypeOps,
  communityStatusMap,
  communityStatusOps,
  searchRegionTypeMap,
  searchRegionTypeOps,
  orgSourceMap,
  orgSourceOps,
  jumpGoCodeMap,
  isTopMap,
  isTopOps,
  sourceMap,
  sourceOps,
  nodeLevelMap,
  nodeLevelOps,
  sendImmediatelyMap,
  sendImmediatelyOps,
  statusMap,
  labelLevelOps,
  statusOps,
  // sendTypeMapPermission
  _integrationType,
  integrationTypeOps, 
  isSelfsupportOps, 
  isSupportAftersalesOps, 
  isOpenOps,
  serviceScopeMap,
  deliveryTypeMap,
  storeStatusMap,
  serviceScopeOps,
  deliveryTypeOps,
  setIsGrantOps,
  storeStatusOps
}
