// 获取列表
const getListURL = `${API_CONFIG.butlerBaseURL}notice/getNoticeList`
// app列表
const APPURL = `${API_CONFIG.butlerBaseURL}appInfo/selectAppinfo?appType=`
// 删除通知
const deleteNoticeURL = `${API_CONFIG.butlerBaseURL}notice/deleteNotice`
// 批量删除通知
const batchDeleteNoticeUrl = `${API_CONFIG.butlerBaseURL}notice/batchDeleteNotice`
// 发布人归属
const getOrganizeURL = `${API_CONFIG.baseURL}serverRoleAction!getOrganize.action`
// 组织
const getSysOrgURL = `${API_CONFIG.butlerBaseURL}orgInfo/searchOrgByKeyword`
// 导出列表
const exportListURL = `${API_CONFIG.butlerBaseURL}notice/export`
// 上传
const uploadURL = `${API_CONFIG.uploadURL}?module=shopgoods`
// multi-select 业主列表
const getOwnerListURL = `${API_CONFIG.baseURL}serverOwnerAction!getOwnerList.action`
// multi-select 物管人员列表
const getReceiveUserListURL = `${API_CONFIG.baseURL}serverUserAction!viewList.action`
// multi-select 项目列表
const getCommunityListURL = `${API_CONFIG.controlBaseURL}community/getCommunityList`
// multi-select 楼幢列表
const getBuiddingListURL = `${API_CONFIG.butlerBaseURL}communityNode/listBuildingNode`
// multi-select 组织列表
const getOrgIdsListURL = `${API_CONFIG.butlerBaseURL}orgInfo/organizations?isPartyOrg=0`
// 新增
const submitCreateFormURL = `${API_CONFIG.controlBaseURL}notice/addNotice`
// 获取角色
const getRoleTypeURL = `${API_CONFIG.baseURL}serverUserAction!getRoleType.action`
// select2 运营项目组
const getOperateRegionListURL = `${API_CONFIG.baseURL}serverCodewordAction!getOperateRegions.action`
// 项目阶段状态
const getCommunityStageURL = `${API_CONFIG.baseURL}serverCodewordAction!getProjectTypes.action`
// 所属区域
const geAllRegionURL = `${API_CONFIG.controlBaseURL}region/select2`
// 签收概览列表
const getSignOverviewListURL = `${API_CONFIG.shopBaseURL}business/notice/getNoticeSignList`
// 签收概览导出
const getSignOverviewExportURL = `${API_CONFIG.butlerBaseURL}notice/exportSignList`
// 检测园区是否可以跳转
const checkSkipCommunityURL = `${API_CONFIG.shopBaseURL}skipLifeShu/checkSkipCommunity`
// 角色类型
const getRoleNameListURL = `${API_CONFIG.baseURL}serverUserAction!getRoles.action`
// 项目类型
const getCommunityTypeURL = `${API_CONFIG.baseURL}serverCodewordAction!getRoomTypes.action`
// 组织标签
const getOrgTagURL = `${API_CONFIG.butlerBaseURL}orgTag/getDropDownSearchTag`
// 关联房号
const getUserRoomURL = `${API_CONFIG.baseURL}serverCodewordAction!getUserRooms.action`
// 组织超管
const superManagerURL = `${API_CONFIG.butlerBaseURL}user/searchOrgManage/keyword`
// 顶层组织
const getTopOrgURL = `${API_CONFIG.butlerBaseURL}orgInfo/organization/searchOrg`

// 获取标签列表
const getListIdsURL = `${API_CONFIG.controlBaseURL}label/getLabelList`

// 获取分类选项
const categoryOptionsURL = `${API_CONFIG.controlBaseURL}label/getCategoryByParentId`

// 获取商店列表
const getShopListURL = `${API_CONFIG.shopBaseURL}shop/page`

// 获取门店列表
const getStoreListURL = `${API_CONFIG.shopBaseURL}store/page`

// 获取商家收款单位列表
const getLegalUnitIdURl = `${API_CONFIG.butlerBaseURL}legalUnit/legalUnitSelectTo`

// 获取商家列表
const getShopURL = `${API_CONFIG.baseURL}serverCodewordAction!getShops.action`

export {
  getListURL,
  APPURL,
  getOrganizeURL,
  getSysOrgURL,
  exportListURL,
  uploadURL,
  getOwnerListURL,
  getReceiveUserListURL,
  submitCreateFormURL,
  getRoleTypeURL,
  getCommunityListURL,
  getBuiddingListURL,
  getOrgIdsListURL,
  getOperateRegionListURL,
  getCommunityStageURL,
  geAllRegionURL,
  getSignOverviewListURL,
  getSignOverviewExportURL,
  deleteNoticeURL,
  batchDeleteNoticeUrl,
  checkSkipCommunityURL,
  getRoleNameListURL,
  getCommunityTypeURL,
  getOrgTagURL,
  getUserRoomURL,
  superManagerURL,
  getTopOrgURL,
  getListIdsURL,
  categoryOptionsURL,
  getShopListURL,
  getStoreListURL,
  getLegalUnitIdURl,
  getShopURL
}
